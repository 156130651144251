import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {enableProdMode} from '@angular/core';

enableProdMode();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Über mich',
      url: '/home',
      icon: 'hand'
    },
    {
      title: 'Links',
      url: '/home',
      icon: 'globe'
    },
    {
      title: 'Vita',
      url: '/home',
      icon: 'person'
    },
    {
      title: 'Impressum',
      url: '/impressum',
      icon: 'information-circle-outline'
    }
  ];

  constructor(
    private platform: Platform,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
    });
  }
}
